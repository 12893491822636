:root {
  --input-border-color: #d1d5db;
  --input-end-background: #f3f4f6;
  --text-title: #15181e;
  --text-body: #42454d;
  --text-muted: #696d77;
  --text-disabled: #9ea1a9;
  --border-active: #cfd4d8;
  --border-container: #dadee3;
  --icon-gray: #9e9e9e;
  --dropdown-gray: #f9fafb;
  --blue-200: #b6c1d7;
  --blue-400: #6d82b0;
  --blue-500: #48639c;
  --blue-600: #324c85;
  --blue-700: #263963;
  --toggle-btn-color: #4b5563;
  --red-50: #ffeff0;
  --red-400: #fc8086;
  --red-500: #fb6068;
  --red-600: #d3434a;
  --gray-50: #f6f8fa;
  --gray-100: #edf2f4;
  --gray-700: #6a6e72;
  --gray-800: #373f45;
  --gray-900: #252b30;
  --yellow-50: #fdf8f1;
  --yellow-500: #ecb871;
  --yellow-700: #a6712c;
  --font-family: "Roboto", sans-serif;
}

* {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 0;
  }
}
@keyframes appear-in {
  0% {
    opacity: 1;
  }
  90% {
    width: 100%;
    height: 100%;
    opacity: 0.1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes inleftEase {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes outleftEase {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.w-full {
  width: 100%;
}

.flex-grow-1 {
  flex-grow: 1;
}

.p-component {
  font-family: var(--font-family);
}

.background-lightgray {
  background-color: var(--gray-100);
}

.install-prompt-background {
  background-color: var(--gray-900);
}

.menu-item {
  padding: 12px;
}

.sidebar {
  box-shadow: rgba(0, 0, 0, 0.2) 1.95px 1.95px 3px;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  user-select: none;
  flex-shrink: 0;
}

.sidebar-hidden {
  display: none;
}

.sidebar-background {
  background-color: var(--gray-800);
}

.active-background {
  background-color: var(--gray-900);
}

.sidebar-item:hover {
  background-color: var(--gray-900);
}

.header {
  height: 60px;
}

.p-dropdown .p-dropdown-label {
  color: var(--text-muted);
  font-size: 14px;
}

.p-dropdown .p-dropdown-trigger {
  color: var(--icon-gray);
}

.input-container {
  border: 1px solid var(--input-border-color);
  transition: border-color 0.5s;
}

.input-container-focused {
  border: 1px solid var(--blue-400);
  transition: border-color 0.5s;
}

.eye-label {
  min-height: 42px;
  max-width: 42px;
  padding: 10px;
  color: #324c85;
  font-weight: 500;
}

.res-adornment {
  min-width: 1.8rem;
  min-height: 42px;
  padding: 10px;
  padding-left: 0;
  border: 1px solid var(--input-border-color);
  color: var(--text-muted);
  transition: border-color 0.5s;
}

.adornment-input-text {
  min-width: 1.8rem;
}

.adornment-text {
  color: var(--text-muted);
  font-size: 14px;
  padding: 10px;
  padding-left: 0;
}

.adornment-hidden {
  visibility: hidden;
}

.text-dark-gray {
  color: var(--text-body);
}

.divider {
  height: 1px;
}

.sidebar-divider {
  height: 1px;
  width: 100%;
  background-color: var(--gray-700);
}

.card-with-common-eye .p-card-content {
  padding-top: 6px;
}

.result-table .p-card-content {
  padding: 0;
}

.result-item {
  background-color: var(--input-end-background);
}

.dark-button {
  background-color: var(--blue-600);
  border: none;
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: white
}

.dark-button:hover {
  background-color: var(--blue-700);
}

.dark-button:focus-within {
  background-color: var(--blue-500);
}

.p-button:focus {
  box-shadow: none;
}

.p-button {
  border-radius: 6px;
}

.p-button-group .p-button:first-of-type {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.p-button-group .p-button:not(:first-of-type):not(:last-of-type) {
  border-radius: 0;
}

.p-button-group .p-button:last-of-type {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.p-button-label {
  font-weight: 400;
}

.p-selectbutton .p-button {
  padding-left: 12px;
  padding-right: 12px;
  background-color: white;
  color: var(--toggle-btn-color);
  height: 42px;
  border: 1px solid var(--input-border-color);
  width: 96px;
  font-weight: 500;
}

.p-selectbutton .p-button:hover {
  border-color: var(--blue-400);
}

.p-button-group .p-button:not(:last-child) {
  border-right: none;
}

.p-selectbutton .p-button.p-highlight {
  background-color: var(--blue-600);
  color: white;
}

.p-selectbutton .p-button.p-highlight:hover {
  background-color: var(--blue-700);
  color: white;
}

.p-inputtext:focus {
  box-shadow: none;
}

.focused-input {
  border-color: var(--blue-400);
}

.box-shadow {
  box-shadow: 0 0 0 0.2rem var(--blue-200);
  transition: box-shadow 0.5s;
}

.box-shadow-error {
  box-shadow: 0 0 0 0.2rem var(--red-400);
  transition: box-shadow 0.5s;
}

.p-dropdown {
  height: 42px;
}

#refraction-input {
  flex: 1;
}

.p-card-title {
  font-size: 18px;
  font-weight: 500;
  color: var(--text-title);
}

.p-card .p-card-body {
  padding: 16px;
  min-width: 160px;
}
.p-card .p-card-content {
  padding-bottom: 0;
}

.p-dropdown:focus-within {
  box-shadow: 0 0 0 0.2rem var(--blue-200);
  border-color: var(--blue-400);
  transition: all 0.5s;
}

.no-focus:focus-within {
  box-shadow: none !important;
  border-color: var(--input-border-color) !important;
}

.p-dropdown:not(.p-disabled).p-focus {
  border-color: var(--blue-400);
}

.input {
  height: 40px;
  border: 1px solid var(--input-border-color);
  padding: 10px;
  color: var(--text-muted);
  transition: border-color 0.5s;
}

.rotation-input > .p-inputtext {
  color: var(--text-muted);
}

.rotation-input {
  background-color: #fff;
}

.p-inputtext {
  border: none;
}

.input-invalid {
  border: 1px solid var(--red-600);
}

.label-height {
  height: 40px;
}

.not-now-btn:hover {
  color: var(--text-muted);
}

.button-clear {
  color: var(--text-muted);
  border: 1px solid var(--border-active);
  background-color: white;
  transition: all 0.5s;
}

.button-clear:hover {
  border-color: var(--blue-400);
}

.floated-label {
  font-size: 11px;
  color: var(--text-muted);
  top: -7px;
  left: 6.5px;
  background-color: white;
  padding-left: 4px;
  padding-right: 4px;
}

.material-label {
  top: -6px;
}

.select-label {
  font-size: 11px;
  color: var(--text-muted);
  top: -5px;
  left: 7px;
  background-image: linear-gradient(180deg, rgba(244, 244, 246, 1) 0%, rgba(244, 244, 246, 1) 50%, rgba(255, 255, 255, 1) 100%);
  padding-left: 4px;
  padding-right: 4px;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background-color: var(--dropdown-gray);
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: var(--text-title);
}

.p-dropdown .p-dropdown-label {
  padding: 10px;
}

.p-dropdown-item {
  color: var(--text-muted);
  font-size: 14px;
}

.p-dropdown:not(.p-disabled):hover {
  border-color: var(--blue-400);
}

.ripple::before {
  content: "";
  position: absolute;
  z-index: 1;
  width: 0px;
  height: 0px;
  background-color: white;
  left: 0px;
  top: 0px;
  border-bottom-right-radius: 333px;
  animation: appear-in 0.5s;
  animation-timing-function: ease-in;
}

.transition {
  animation: fade-in 0.3s;
  animation-timing-function: ease-in;
}

.transition-out {
  animation: fade-out 0.4s;
  animation-timing-function: ease-out;
}

.lens {
  border-radius: 8px;
  border: 1px solid var(--input-border-color);
}

.rotating-lens {
  touch-action: none;
  will-change: transform;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.lens:hover {
  border-color: var(--blue-400);
  transition: all 0.5s;
}

.p-dialog .p-dialog-header {
  border-bottom: none;
  background-color: white;
}

.relative > ._container_1c03y_8 {
  height: 40px;
}

._container_1c03y_8 > ._dropdown_1c03y_15 {
  background-color: white;
  width: 18rem;
  border-radius: 6px;
  border: 1px solid var(--input-border-color);
  height: 42px;
}

._container_1c03y_8 > ._dropdown_1c03y_15:hover {
  border-color: var(--blue-400);
}

._container_1c03y_8 > ._dropdown_1c03y_15:focus {
  box-shadow: 0 0 0 0.2rem var(--blue-200);
  border-color: var(--blue-400);
  transition: all 0.5s;
}

._dropdown_1c03y_15 > ._selected_country_1c03y_109 {
  color: var(--text-muted);
  font-size: 14px;
  font-weight: 400;
}

._dropdown_1c03y_15 > ._country_flag_1c03y_116:not([src]) {
  display: none;
}

._dropdown_1c03y_15 > svg {
  visibility: hidden;
}

._container_1c03y_8 > ._dropdown_items_wrapper_1c03y_27 {
  width: 18rem;
}

._selected_country_1c03y_109 {
  visibility: hidden;
}

.arrow {
  top: 12px;
  right: 16px;
}

.placeholder-container {
  left: 50px;
  top: 12px;
  max-width: 200px;
  max-height: 20px;
}

.placeholder {
  color: var(--text-muted);
  font-size: 14px;
}

._input_wrapper_1c03y_44 > ._country_search_1c03y_50 {
  border: 1px solid var(--input-border-color);
  border-radius: 6px;
  padding: 10px;
  background-color: white;
  color: var(--text-muted);
}

._input_wrapper_1c03y_44 > ._country_search_1c03y_50::placeholder {
  visibility: hidden;
}

.Toastify > .Toastify__toast-container {
  width: fit-content;
  max-width: 100%;
  max-height: 100vh;
  overflow: hidden;
}

.Toastify__toast-container--bottom-center > .Toastify__toast-theme--colored.Toastify__toast--info {
  background-color: var(--blue-500);
}

.ease-in {
  animation: inleftEase 0.15s ease-in both;
}

.ease-out {
  animation: outleftEase 0.15s ease-out both;
}

.p-dropdown .p-dropdown-trigger {
  max-width: 26px;
  padding-right: 10px;
}

.container-gap {
  gap: 8px;
}

.burger-menu {
  display: block;
  padding: 24px;
}

.rotation-vertex {
  margin-right: 2.1rem;
}

.section-input {
  width: 133px;
}

.section-input-w {
  max-width: 6rem;
  width: auto;
}

.res-section {
  width: 6.29rem;
}

.p-dialog-header {
  background: var(--gray-50);
}

.p-dialog .p-dialog-content {
  background: white;
}
.p-dialog-content .p-card {
  box-shadow: none;
}

.cards-container {
  width: 100%;
  align-items: start;
}

.sphere-cylinder {
  max-width: 4rem;
}

.res-sphere-cylinder {
  width: 65px;
}

.axis {
  max-width: 3rem;
}

.res-axis {
  width: 49px;
}

.radius {
  max-width: 4rem;
}

.res-radius {
  width: 65px;
}

.vertex {
  max-width: 5rem;
}

.prism {
  max-width: 7rem;
  width: 100%;
}

.res-prism {
  width: 150px;
}

.material-select {
  min-width: 161px;
}

.inline-select {
  min-width: 93px;
  border: none;
  border-left: 1px solid var(--input-border-color);
  border-radius: 0 6px 6px 0;
}

.manufacturer-select {
  width: 10rem;
}

.outlet-container {
  padding: 24px;
}

.header-btn {
  height: 42px;
}

.vertex-inp {
  max-width: 6rem;
}

.p-panel .p-panel-content {
  padding: 16px;
}

.p-panel .p-panel-header {
  background-color: #fff;
  border-bottom: none;
  padding: 16px;
}

.p-panel {
  box-shadow:
    0 2px 1px -1px rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 6px;
}

.p-panel-header-icon {
  visibility: hidden;
}

.panel-arrow {
  top: 24px;
  right: 24px;
}

.res-item-container {
  gap: 14px;
}

.material-result {
  width: 161px;
}

.install-prompt-background {
  width: 280px;
}

.setting-item {
  width: 336px;
}

.calcualtion-btns {
  display: flex;
}

.res-table {
  display: flex;
}
.Toastify__toast {
  box-shadow:
    0px 3px 5px rgba(0, 0, 0, 0.02),
    0px 0px 2px rgba(0, 0, 0, 0.05),
    0px 1px 4px rgba(0, 0, 0, 0.08) !important;
  background-color: #c62828 !important;
}
.hide-panel-header .p-panel-header {
  display: none;
}

@media (max-width: 1260px) {
  .container-gap {
    gap: 4px;
  }

  .res-container-gap {
    gap: 4px;
  }

  .section-input {
    width: 131px;
  }

  .sidebar {
    max-width: 240px;
  }

  .sidebar-divider {
    max-width: 208px;
  }

  .menu-item {
    max-width: 208px;
  }

  .install-prompt-background {
    width: 240px;
  }
}

@media (max-width: 1199px) {
  .btn-group {
    position: absolute;
    top: 24px;
    right: 24px;
  }

  .container-gap {
    gap: 10px;
  }

  .res-container-gap {
    gap: 10px;
  }

  .section-input {
    width: 132px;
  }

  .outlet-container {
    margin-top: 30px;
    overflow-x: hidden;
    overflow-y: scroll;
    margin-bottom: 80px;
  }

  .custom-layout {
    display: flex;
    flex-direction: column;
    max-height: 100vh;
  }

  .btn-group {
    position: initial;
  }

  .custom-header {
    z-index: 1;
    position: absolute;
    width: 100%;
    background-color: white;
    padding: 18px 20px;
  }

  .calculation-btns {
    position: fixed;
    bottom: 0;
    left: 0;
    max-width: 100%;
    background-color: white;
    padding: 20px 16px;
  }

  .general-container {
    margin-bottom: 60px;
  }

  .calc-btn {
    width: 70%;
  }

  .reset-btn {
    width: 30%;
  }

  /* .cards-container {
    justify-content: space-evenly;
  } */
}

@media (min-width: 1200px) {
  .burger-menu {
    display: none;
  }

  .sidebar {
    height: auto;
    position: static;
  }

  .sidebar-hidden {
    display: block;
  }
}

@media (max-width: 899px) {
  .cards-container {
    flex-direction: column;
    max-width: 565px;
    width: auto;
    flex: 1;
    align-items: normal;
  }

  .general-container {
    align-items: center;
  }

  .inputs-container {
    justify-content: space-between;
  }

  .sphere-cylinder {
    max-width: 8rem;
  }

  .axis {
    max-width: 7.8rem;
  }

  .section-input-w {
    width: 203px;
  }

  .section-input {
    width: 100%;
  }

  .material-select {
    min-width: fit-content;
    width: 150px;
  }
  .inline-select {
    width: 83px;
  }

  .radius {
    max-width: 7.5rem;
  }

  .rotation-vertex {
    margin-right: 7.6rem;
  }

  .vertex {
    max-width: 7.5rem;
  }

  .vertex-inp {
    max-width: 8rem;
  }

  .outlet-container {
    padding: 20px;
  }
}

@media (max-width: 979px) {
  .outlet-container {
    display: flex;
    justify-content: center;
  }

  .res-section {
    width: 9.4rem;
  }
}

@media (max-width: 703px) {
  .setting-item {
    width: 100%;
  }
}

@media (max-width: 599px) {
  /* .cards-container {
    width: 398px;
    align-content: center;
  } */

  .sphere-cylinder {
    max-width: 4.6rem;
  }

  .axis {
    max-width: 4.6rem;
  }

  .radius {
    max-width: 7rem;
  }

  .vertex {
    max-width: 5rem;
  }

  .rotation-vertex {
    margin-right: 3.3rem;
  }

  .material-select {
    min-width: fit-content;
    width: 303px;
    max-width: 100%;
  }

  /* .input-component {
    flex-grow: 1;
  } */

  .inline-select {
    width: 83px;
  }

  .outlet-container {
    padding: 16px;
  }

  .btn-group {
    top: 16px;
    right: 16px;
  }

  .manufacturer-select {
    width: 8rem;
    height: 32px;
  }

  .burger-menu {
    display: block;
    padding: 16px;
  }

  .header-btn {
    height: 32px;
  }

  .vertex-inp {
    max-width: 7.4rem;
  }

  .res-item-container {
    min-width: auto;
    width: 90px;
  }

  .result-item {
    font-size: 12px;
  }

  .p-dialog-content {
    padding: 16px;
  }

  .p-dialog .p-dialog-header {
    padding: 16px;
    padding-bottom: 0;
  }

  .section-input-w {
    width: 122px;
  }

  .section-input {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .Toastify__toast-container {
    left: 50% !important;
    transform: translateX(-50%) !important;
    right: auto !important;
  }
  .Toastify__toast-container {
    width: max-content !important;
    border-radius: 6px;
    margin-bottom: 20px !important;
  }
}

@media (max-width: 429px) {
  .eye-label {
    width: 30px;
  }

  /* .cards-container {
    width: 320px;
  } */

  .btn-group {
    top: 10px;
    right: 10px;
  }

  .sphere-cylinder {
    max-width: 3.7rem;
  }

  .axis {
    max-width: 2.7rem;
  }

  .radius {
    max-width: 5.55rem;
  }

  .vertex {
    max-width: 4.9rem;
  }

  .rotation-vertex {
    margin-right: 1.5rem;
  }

  .floated-label {
    font-size: 8px;
    top: -6px;
  }

  .material-select {
    min-width: auto;
    width: 250px;
  }
  .inline-select {
    width: 120px;
  }

  .manufacturer-select {
    visibility: hidden;
  }

  .burger-menu {
    padding: 10px;
  }

  .vertex-inp {
    max-width: 4.95rem;
  }

  .p-dialog-content {
    padding: 8px;
    padding-bottom: 12px;
  }

  .p-dialog .p-dialog-header {
    padding: 8px;
    padding-bottom: 0;
    padding-top: 12px;
  }

  .p-card .p-card-body {
    padding: 10px;
  }

  .res-container-gap {
    gap: 2px;
  }

  .section-input-w {
    width: 82px;
  }

  .section-input {
    width: 100%;
  }

  .input {
    font-size: 12px;
  }

  .adornment-text {
    font-size: 12px;
  }
}

@media (max-width: 359px) {
  .res-item-container {
    min-width: auto;
    width: 80px;
  }

  .result-item {
    padding: 4px;
  }

  .p-card .p-card-body {
    padding: 8px;
  }
}
