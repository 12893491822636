@import "primeflex/primeflex.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  background-color: #f4f4f6;

  -webkit-tap-highlight-color: transparent;

  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);

  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
}

@media (hover: none) {
  .cursor {
    pointer-events: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
button {
  cursor: pointer;
}

button,
label,
a,
ul,
li {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
